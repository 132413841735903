<template>
  <div class="px-16px">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ItemComp class="mt-16px" v-for="(item, index) in dataList" :key="index" :item="item"></ItemComp>
    </van-list>
     <loading v-if="isFistLoading"></loading>
  </div>
</template>

<script>
import ItemComp from './components/ItemComp.vue'
import loading from '@/components/loading.vue'
export default {
  components: {
    ItemComp,
    loading
  },
  data() {
    return {
      isFistLoading:true,
      num: 1,
      loading: false,
      finished: false,
      dataList: [],
    }
  },
  methods: {
    async onLoad() {
       const {id}=this.$route?.query ?? {}
     try{
       const res = await this.$api.get('/v1/articles', { params: { category_id: id, key: this.key, page: this.num, per_page: 20 }, })
      this.dataList = this.dataList.concat(res.data);
      this.num++
      if (res.data.length < 20) {
        this.finished = true;
      }
      this.loading = false;
     }catch(err){console.log(err)}finally{
      setTimeout(() => {
        this.isFistLoading=false
      }, 200);
     }
    },
  }
}
</script>

<style>


</style>