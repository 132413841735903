<template>
    <div class=" text-hex-000000 text-17px" @click="jumpByOption(item)">
            <div class="flex items-center">
                <van-image lazy-load class="flex-none " width="140" height="100" radius="8" :src="item.cover" />
                <div class="line-2  ml-19px leading-2 ">{{ item.title }}</div>
            </div>
            <div class="mt-16px">
                <van-divider />
            </div>
    </div>
</template>
<script>
import {jumpByOption} from '@/utils/index'
export default {
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    methods:{
        jumpByOption
    }
}
</script>
<style scoped>

.line-2 {
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

</style>