<template>
   <div class="text-17px text-hex-000000" @click="jumpByOption(item)">
       <van-image  lazy-load width="100%" height="200" radius="8" :src="item.cover" />
      <div class="mt-8px line-1">{{item.title}}</div>
   </div>
</template>

<script>
import {jumpByOption} from '@/utils/index'
export default {
 props:{
    item:{
      type:Object,
      default: ()=>{}
    }
 },
 methods:{
  jumpByOption
 }
}
</script>

<style scoped>

.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 30px;     
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}


</style>