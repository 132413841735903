<template>
  <div class=" text-hex-000000 text-17px" @click="jumpByOption(item)">
    <div class="flex items-center">
      <van-image lazy-load class="flex-none " width="140" height="100" radius="8" :src="item.cover" />
      <div class="ml-19px ">
        <div class="line-1">{{ item.title }}</div>
        <div class="line-2 text-15px text-hex-999999 mt-16px leading-20px">{{ item.subtitle }}</div>
      </div>
    </div>
    <div class="mt-16px">
      <van-divider />
    </div>
  </div>
</template>
<script>
import {jumpByOption} from '@/utils/index'
export default {
  props: {
    item: {
      type: Object,
      default: () => { }
    }
  },
  methods:{
    jumpByOption
  }
}
</script>
<style scoped>

.line-2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 30px;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

</style>